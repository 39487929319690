(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/tipsinfo/assets/javascripts/tipsinfo.js') >= 0) return;  svs.modules.push('/components/tipsen/tipsinfo/assets/javascripts/tipsinfo.js');
"use strict";

const {
  useSelector
} = ReactRedux;
const {
  selectShowMatchAnalyseForecast
} = svs.components.tipsen.selectors;
const {
  selectSportEvent
} = svs.components.sportinfo.matchInfoRedux.selectors;
const {
  AnalysisForecast,
  TipsinfoRow,
  TipsinfoRowText,
  TipsinfoRowStats
} = svs.components.tipsen.tipsinfo;
const {
  EventTypeId,
  EventTypeField
} = svs.components.sportinfo.common.constants;
const {
  selectEngineDefinition
} = svs.components.tipsen.engine.selectors;
const {
  createSelectFormattedDistribution,
  createSelectFormattedOdds,
  formatDecimalOdds,
  formatPercentage,
  getOddsCaption
} = svs.components.tipsen.tipsinfo.utils;
const {
  useBetEvent
} = svs.components.sportinfo.matchInfoHooks;
const {
  useIsTipsinfoEnabled
} = svs.components.tipsen.hooks;
const {
  createEventStatisticsKey
} = svs.components.sportinfo.common.helpers;
const {
  selectEventStatisticCurrentValueByType
} = svs.components.sportinfo.matchInfoRedux.selectors;
const {
  Odds: formatOdds
} = svs.utils.format;
const {
  useContext
} = React;
const {
  RenderContext
} = svs.component.tipsen.renderContext;
const {
  Trend
} = svs.components.lbUi.trend;
const getOverUnderOddsAsText = oddsValues => {
  if (!oddsValues || oddsValues.length < 2) {
    return '-';
  }
  return "".concat(formatOdds(oddsValues[0]) || '-', "/").concat(formatOdds(oddsValues[1]) || '-');
};
const Tipsinfo = _ref => {
  var _tioTidningarTips$cur;
  let {
    drawNumber,
    productId,
    eventNumber,
    outcomeLabels,
    isReduceFree = false
  } = _ref;
  const {
    betEvent,
    eventStatistics
  } = useBetEvent({
    productId,
    drawNumber,
    eventNumber
  });
  const {
    matchId,
    eventTypeId,
    periodNumber
  } = betEvent;
  const {
    pageType,
    isResultOrMybets
  } = useContext(RenderContext);
  const sportEvent = useSelector(state => selectSportEvent(state, matchId));
  const isHead2Head = betEvent.eventTypeId === EventTypeId.HEAD_TO_HEAD;
  const selectFormattedOdds = createSelectFormattedOdds({
    matchId,
    eventTypeId,
    periodNumber
  });
  const selectFormattedDistribution = createSelectFormattedDistribution({
    drawNumber,
    eventTypeId,
    matchId,
    periodNumber,
    productId
  });
  const {
    isMatchStartEnabled,
    isTioTidningarEnabled,
    isStartOddsEnabled,
    isOddsEnabled,
    isFavouritesEnabled,
    isSvenskaFolketEnabled,
    isSvenskaFolketAsOutcomesEnabled,
    isOverUnderEnabled
  } = useIsTipsinfoEnabled(pageType);
  const {
    favourites,
    startOdds,
    tioTidningarTips
  } = eventStatistics || {};
  const eventStatisticsKey = createEventStatisticsKey({
    matchId,
    eventTypeId: EventTypeId.OVER_UNDER,
    periodNumber: betEvent.periodNumber
  });
  const overUnderOdds = useSelector(state => selectEventStatisticCurrentValueByType(state, eventStatisticsKey, EventTypeField.Odds));
  const shouldDisplayLargeTipsinfo = isSvenskaFolketAsOutcomesEnabled;
  const shouldDisplaySvenskaFolket = isSvenskaFolketEnabled && (!isSvenskaFolketAsOutcomesEnabled || isReduceFree);
  const shouldDisplayOdds = isOddsEnabled && !isHead2Head;
  const shouldDisplayStartOdds = isStartOddsEnabled;
  const shouldDisplayTioTidningar = isTioTidningarEnabled && !isHead2Head;
  const shouldDisplayMatchstart = isMatchStartEnabled;
  const shouldDisplayFavourites = isFavouritesEnabled;
  const hasTipsinfoStats = shouldDisplayOdds || shouldDisplayStartOdds || shouldDisplaySvenskaFolket || shouldDisplayTioTidningar || shouldDisplayFavourites || shouldDisplayMatchstart;
  const shouldDisplayOverUnder = isOverUnderEnabled;
  const showMatchAnalyseForecast = useSelector(state => selectShowMatchAnalyseForecast(state, pageType));
  const hasTioTidningarValues = (tioTidningarTips === null || tioTidningarTips === void 0 || (_tioTidningarTips$cur = tioTidningarTips.current) === null || _tioTidningarTips$cur === void 0 || (_tioTidningarTips$cur = _tioTidningarTips$cur.value) === null || _tioTidningarTips$cur === void 0 ? void 0 : _tioTidningarTips$cur.length) > 0;
  const outcomeLabelsLength = (outcomeLabels === null || outcomeLabels === void 0 ? void 0 : outcomeLabels.length) || 0;
  const currentEngine = useSelector(selectEngineDefinition);
  const isGoalCount = currentEngine.outcomes.eventTypeId === EventTypeId.GOAL_COUNT;
  return React.createElement("div", {
    className: "tipsinfo tipsinfo-".concat(outcomeLabelsLength, "col")
  }, isReduceFree && hasTipsinfoStats && React.createElement(TipsinfoRow, {
    className: "tipsinfo-bet-options-headers",
    dataTestid: "coupon-row-tipsinfo-bet-options-headers"
  }, React.createElement("div", {
    className: "tipsinfo-statistics-grid"
  }, outcomeLabels === null || outcomeLabels === void 0 ? void 0 : outcomeLabels.map(el => React.createElement("div", {
    className: "f-center",
    key: el.text
  }, el.text)))), shouldDisplaySvenskaFolket && React.createElement(TipsinfoRow, {
    dataTestid: "coupon-row-tips-info-svenska-folket",
    title: "Svenska folket"
  }, React.createElement("div", {
    className: "tipsinfo-statistics-grid"
  }, outcomeLabels === null || outcomeLabels === void 0 ? void 0 : outcomeLabels.map((el, i) => React.createElement(Trend, {
    key: "".concat(drawNumber, "_").concat(matchId, "_").concat(el.text),
    postfixSymbol: "%",
    selectorFunction: selectFormattedDistribution(i),
    uniqueId: "".concat(drawNumber, "_").concat(matchId, "_").concat(el.text)
  })))), shouldDisplayOdds && React.createElement(TipsinfoRow, {
    dataTestid: "coupon-row-tips-info-odds",
    title: getOddsCaption({
      isStartOddsEnabled,
      isResultOrMybets
    })
  }, React.createElement("div", {
    className: "tipsinfo-statistics-grid"
  }, outcomeLabels === null || outcomeLabels === void 0 ? void 0 : outcomeLabels.map((el, i) => React.createElement(Trend, {
    key: "".concat(drawNumber, "_").concat(matchId, "_").concat(el.text),
    selectorFunction: selectFormattedOdds(i),
    uniqueId: "".concat(drawNumber, "_").concat(matchId, "_").concat(el.text)
  })))), shouldDisplayFavourites && React.createElement(TipsinfoRow, {
    dataTestid: "coupon-row-tips-info-favourites",
    title: "Favoritskap"
  }, React.createElement(TipsinfoRowStats, {
    className: shouldDisplayLargeTipsinfo ? 'tipsinfo-row-stats-option--lg' : '',
    sign: "%"
  }, outcomeLabels === null || outcomeLabels === void 0 ? void 0 : outcomeLabels.map((_, i) => {
    var _favourites$current;
    return formatPercentage(favourites === null || favourites === void 0 || (_favourites$current = favourites.current) === null || _favourites$current === void 0 ? void 0 : _favourites$current.value[i]);
  }))), shouldDisplayStartOdds && React.createElement(TipsinfoRow, {
    dataTestid: "coupon-row-tips-info-start-odds",
    title: "Startodds"
  }, React.createElement(TipsinfoRowStats, null, outcomeLabels === null || outcomeLabels === void 0 ? void 0 : outcomeLabels.map((_, i) => {
    var _startOdds$current;
    return formatDecimalOdds(startOdds === null || startOdds === void 0 || (_startOdds$current = startOdds.current) === null || _startOdds$current === void 0 ? void 0 : _startOdds$current.value[i]);
  }))), shouldDisplayTioTidningar && React.createElement(TipsinfoRow, {
    dataTestid: "coupon-row-tips-info-ten-papers",
    title: "Tio Tidningar"
  }, React.createElement(TipsinfoRowStats, {
    noValueSign: hasTioTidningarValues ? '0' : '-'
  }, outcomeLabels === null || outcomeLabels === void 0 ? void 0 : outcomeLabels.map((_, i) => {
    var _tioTidningarTips$cur2;
    return tioTidningarTips === null || tioTidningarTips === void 0 || (_tioTidningarTips$cur2 = tioTidningarTips.current) === null || _tioTidningarTips$cur2 === void 0 ? void 0 : _tioTidningarTips$cur2.value[i];
  }))), shouldDisplayOverUnder && isGoalCount && React.createElement("div", {
    className: "tipsinfo-border-fulltraff"
  }), shouldDisplayOverUnder && React.createElement(TipsinfoRow, {
    className: "coupon-row-tips-info-overunder",
    dataTestid: "coupon-row-tips-info-overunder",
    isGoalCount: isGoalCount,
    title: "Odds - \xD6ver/Under 2,5 m\xE5l"
  }, React.createElement(TipsinfoRowText, {
    className: "tipsinfo-row-matchstart margin-left-half"
  }, getOverUnderOddsAsText(overUnderOdds))), shouldDisplayMatchstart && isGoalCount && React.createElement("div", {
    className: "tipsinfo-border-fulltraff"
  }), shouldDisplayMatchstart && React.createElement(TipsinfoRow, {
    className: "coupon-row-tips-info-matchstart",
    dataTestid: "coupon-row-tips-info-matchstart",
    date: sportEvent.matchStart,
    isGoalCount: isGoalCount,
    title: "".concat(isGoalCount ? 'Matchstart' : 'Matchstart:')
  }, React.createElement(TipsinfoRowText, {
    className: "tipsinfo-row-matchstart margin-left-half"
  }, svs.components.lbUtils.helpers.formatDate.formatDate(sportEvent.matchStart, true, true, true))), showMatchAnalyseForecast && isGoalCount && React.createElement("div", {
    className: "tipsinfo-border-fulltraff"
  }), showMatchAnalyseForecast && React.createElement(AnalysisForecast, {
    dataTestid: "coupon-row-tips-info-analyse",
    drawId: drawNumber,
    eventNumber: eventNumber,
    matchId: matchId,
    productId: productId
  }));
};
setGlobal('svs.components.tipsen.tipsinfo.Tipsinfo', Tipsinfo);

 })(window);